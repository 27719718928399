.App{
  background: url("./img/range.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

html{
  scroll-behavior: smooth;
}

body,
html {
  width: 100%;
  height: 100%;
  background-color: #FFF;
}

body {
  font-family: 'Merriweather', 'Helvetica Neue', Arial, sans-serif;
  overflow-x: hidden;
}

a {
  color: #fff;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

a:hover {
  background-color: #000;
  color: #fff !important;
}

#mainNav{
  background-color: #052F5F;
}

.navlink {
  display: block;
  color: white !important;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  background-color: #052F5F;
}

h1,
h2,
h3{
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
  font-size: large;
  font-weight: bold;
}

section {
  padding: 5rem 0;
}

.section-heading {
  background: url("img/range.jpg");
  
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  
  font-size: 70px;
  font-weight: bold;
  text-align: center;
}


.masthead {
  width: 100vw;
  height: 100vh;
}

.mastheadContent{
  padding-top: 10%;
  padding-left: 5%;
  padding-right: 5%;
  text-align: center;
}

#name{
  padding-left: 15%;
  text-align: left;

}

h1 {
  padding-top: 1%;
  font-size: 100px;
  color: white;
}

@media (max-width: 480px){
  h1 {
    font-size: 50px;
  }
}

.introtxt{
  font-size: large;
}

.edu-info{
  width: 92%;
}

.edu-date{
  justify-content: right;
}

.exp-info{
  width: 85%;
}

.btn {
  font-weight: 700;
  text-transform: uppercase;
  background-color: #fff;
  color: #000;
  border: 1px solid #FFA500 !important;
  border-radius: 300px;
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
}

.btn-xl {
  padding: 1rem 2rem;
  margin-top: 20%;
}

:root {
  --marquee-width: 65vw;
  --marquee-height: 20vh;
  --marquee-elements: 11;
  --marquee-elements-displayed: 5;
  --marquee-element-width: calc(var(--marquee-width) / var(--marquee-elements-displayed));

}

.marquee {
  width: var(--marquee-width);
  height: var(--marquee-height);
  background-color: transparent;
  color:#FFF;
  overflow: hidden;
  position: relative;
}

.marquee:before, .marquee:after {
  position: absolute;
  top: 0;
  width: 10rem;
  height: 100%;
  content: "";
  z-index: 1;
}

.marquee:before {
  left: 0;
}

.marquee:after {
  right: 0;
}

.marquee-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--marquee-element-width);
  max-height: 100%;
  font-size: calc(var(--marquee-height)*0.5); /* 5rem; */
  white-space: nowrap;
}

.marquee-content img {
  width: 100%;
  /* height: 100%; */
  border: 2px solid #eee;
}

@media (max-width: 600px) {
  html { font-size: 12px; }
  :root {
    --marquee-width: 100vw;
    --marquee-height: 16vh;
    --marquee-elements-displayed: 3;
  }
  .marquee:before, .marquee:after { width: 5rem; }
}

.btn:hover{
  color:#fff !important;
  background-color: #052F5F !important;
}


.link-text{
  color: #fff !important;
}

#contact{
  color: white !important;
  font-size: 30px;;
}
.contact-heading{
  color: white ;
  font-size: 70px;
  font-weight: bold;
  text-align: center;
}

#about{
  background-color: white;
}

#experience{
  background-color: white;
}

.skills-heading{
  color: white ;
  font-size: 70px;
  font-weight: bold;
  text-align: center;
}

#portfolio{
  background-color: white;
}

.projectBox{
  background-color: #fff;
  color: black;
  border-radius: 1em;
  font-size: larger;
  height: 250px;
  width: 360px;
}

.projectBox-content{
  padding: 5px;
}

.vl {
  border-right: 6px solid transparent;
  border-image: url(./img/range.jpg) 30 round;
  height: 50px;
  position: relative;
  top: 0;
}

.project-name{
  padding-left: 5%;
}

.contactLinks{
  color: #fff;
  padding: 1%;
  justify-content: center;
  font-size: 40px;
}